
export default {
    name: 'LandingPageHeader',
    props: {
        image: {
            type: String,
            required: true,
        },
    },
};


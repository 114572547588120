import { render, staticRenderFns } from "./index.vue?vue&type=template&id=015cbb64&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=015cbb64&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "015cbb64",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormSiteButton: require('/opt/atlassian/pipelines/agent/build/components/form/SiteButton.vue').default,LayoutLandingPageHeader: require('/opt/atlassian/pipelines/agent/build/components/layout/LandingPageHeader.vue').default,HomePageSection: require('/opt/atlassian/pipelines/agent/build/components/HomePageSection.vue').default})


import { mapState } from 'vuex';
import IconChartLine from '~/components/icon/IconChartLine.vue';
import IconIdBadge from '~/components/icon/IconIdBadge.vue';
import IconUserShield from '~/components/icon/IconUserShield.vue';
import IconVideo from '~/components/icon/IconVideo.vue';

export default {
    name: 'Home',
    middleware: 'notAuthenticated',
    async asyncData({ store }) {
        await Promise.all([store.dispatch('reports/get')]);
    },
    computed: {
        ...mapState('reports', {
            reports: (state) => state.data.slice(0, 3),
        }),
        press() {
            return [
                {
                    src: '/images/home/press/bbc.svg',
                    alt: 'BBC News',
                },
                {
                    src: '/images/home/press/mail.svg',
                    alt: 'Mail Online',
                },
                {
                    src: '/images/home/press/itv.svg',
                    alt: 'ITV News',
                },
                {
                    src: '/images/home/press/dailymirror.svg',
                    alt: 'Daily Mirror',
                },
                {
                    src: '/images/home/press/evening-standard.svg',
                    alt: 'Evening Standard',
                },
                {
                    src: '/images/home/press/5news.svg',
                    alt: 'Channel 5',
                },
                {
                    src: '/images/home/press/telegraph.svg',
                    alt: 'The Telegraph',
                },
                {
                    src: '/images/home/press/sun.svg',
                    alt: 'The Sun',
                },
                {
                    src: '/images/home/press/express.svg',
                    alt: 'Daily Express',
                },
                {
                    src: '/images/home/press/guardian.svg',
                    alt: 'The Guardian',
                },
                {
                    src: '/images/home/press/times.svg',
                    alt: 'The Times',
                },
                {
                    src: '/images/home/press/sky.svg',
                    alt: 'Sky News',
                },
            ];
        },
        sections() {
            return [
                {
                    sections: [
                        {
                            title: this.$t('home.heading_2'),
                            titleLink: 'waste-removal',
                            text: this.$t('home.text_2'),
                            link: {
                                class: 'w-40',
                                text: this.$t('waste_removal.get_quotes'),
                                url: 'waste-removal',
                            },
                        },
                        {
                            title: this.$t('home.heading_3'),
                            titleLink: 'business',
                            text: this.$t('home.text_3'),
                            link: {
                                class: 'w-40',
                                text: this.$t('home.find_out_more'),
                                url: 'business',
                            },
                        },
                        {
                            title: this.$t('home.heading_1'),
                            titleLink: 'customer-guarantee',
                            text: this.$t('home.text_1'),
                            link: {
                                class: 'w-40',
                                text: this.$t('home.find_out_more'),
                                url: 'customer-guarantee',
                            },
                        },
                    ],
                },
                {
                    title: this.$t('home.heading_reports'),
                    cover: `${this.$config.cdn_domain}/images/cover/latest-reports`,
                    coverMobile: `${this.$config.cdn_domain}/images/cover/latest-reports-small`,
                    text: this.$t('home.text_reports'),
                    reports: this.reports,
                    link: {
                        text: this.$t('home.view_reports'),
                        url: 'flytipping',
                    },
                },
                {
                    title: this.$t('home.heading_9'),
                    icon: IconVideo,
                    text: this.$t('home.text_9'),
                    youtubeId: 'WUq20THEKpI',
                },
                {
                    title: this.$t('home.heading_6'),
                    icon: IconUserShield,
                    image: '/images/home/trusted_trader_scheme',
                    imageAlt: this.$t('home.litter_picking_alt'),
                    text: this.$t('home.text_6'),
                    link: {
                        class: 'mx-auto',
                        text: this.$t('listings_add.get_quotes_to_collect'),
                        url: 'waste-removal',
                    },
                },
                {
                    title: this.$t('home.heading_7'),
                    icon: IconChartLine,
                    youtubeId: 'QkBu9Jrh1jY',
                    text: this.$t('home.text_7'),
                },
                {
                    title: this.$t('home.heading_8'),
                    icon: IconIdBadge,
                    text: this.$t('home.text_8'),
                    link: {
                        class: 'block max-w-lg mx-auto',
                        text: this.$t('home.find_out_more'),
                        url: 'business',
                    },
                },
                {
                    title: this.$t('services.title'),
                    text: 'Get the cheapest waste removal quotes from our trusted traders, using our price comparison service. ',
                    sections: [
                        {
                            title: this.$t('services.services.man_in_a_van'),
                            titleLink: 'services-man-and-van',
                            text: this.$t('waste_removal.van_description'),
                            link: {
                                class: 'w-40',
                                text: this.$t('home.find_out_more'),
                                url: 'services-man-and-van',
                            },
                        },
                        {
                            title: this.$t('services.services.skip_hire'),
                            titleLink: 'services-skip-hire',
                            text: this.$t('waste_removal.skip_description'),
                            link: {
                                class: 'w-40',
                                text: this.$t('home.find_out_more'),
                                url: 'services-skip-hire',
                            },
                        },
                        {
                            title: this.$t('services.services.grab_bag'),
                            titleLink: 'services-grab-bags',
                            text: this.$t('waste_removal.grab_description'),
                            link: {
                                class: 'w-40',
                                text: this.$t('home.find_out_more'),
                                url: 'services-grab-bags',
                            },
                        },
                    ],
                },
            ];
        },
    },
};
